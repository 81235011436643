export interface IERCProtocolsFields {
  INVENTORY_ARCHIVE: string;
  INVENTORY_FUND: string;
  INVENTORY_OIK: string;
  INVENTORY_NUMBER: string;
  INVENTORY_YEAR: string;
  INVENTORY_TITLE: string;
  INVENTORY_STATUS: string;
  INVENTORY_TYPE: string;
  INVENTORY_KIND: string;
  INVENTORY_CATEGORY: string;
  INVENTORY_APPROVE_DATE: string;
  INVENTORY_ADD_DATE: string;
  HISTORICAL_REFERENCE: string;
  COMMENT: string;
  INVENTORY_DOCS: string;
  INVENTORY_ID: string;
  INVENTORY_PROTOCOL_HEADER: string;
  INVENTORY_PROTOCOL_ID: string;

  FILTER_INVENTORY_ADD_DATE_FROM: string;
  FILTER_INVENTORY_ADD_DATE_TO: string;

  COMMENT_UPDATE_USER: string;
  COMMENT_UPDATE_DATE: string;

  FILTER_KEYWORD: string;
  FILTER_SEARCH_MORPH: string;
  FILTER_SEARCH_NGRAMED: string;
  FILTER_SEARCH_SYNONYM: string;
  FILTER_SEARCH_FREQUENCY: string;
  FILTER_OIK: string;
  FILTER_FUND: string;
  FILTER_YEAR: string;
  FILTER_STATUS: string;
  FILTER_KIND: string;
  FILTER_TYPE: string;
  FILTER_CATEGORY: string;
}

export const fields: IERCProtocolsFields = {
  INVENTORY_ARCHIVE: "inventoryArchive",
  INVENTORY_FUND: "inventoryFund",
  INVENTORY_OIK: "oikShortName",
  INVENTORY_NUMBER: "inventoryNumber",
  INVENTORY_YEAR: "inventoryYear",
  INVENTORY_PROTOCOL_HEADER: "inventoryProtocolHeader",
  INVENTORY_PROTOCOL_ID: "inventoryProtocolId",
  INVENTORY_TITLE: "header",
  INVENTORY_STATUS: "status",
  INVENTORY_KIND: "kind",
  INVENTORY_TYPE: "type",
  INVENTORY_CATEGORY: "category",
  INVENTORY_APPROVE_DATE: "date",
  INVENTORY_ADD_DATE: "createDate",
  HISTORICAL_REFERENCE: "historicalNote",
  COMMENT: "comment",
  INVENTORY_DOCS: "inventoryDocs",
  INVENTORY_ID: "id",

  FILTER_KEYWORD: "keyword",
  FILTER_SEARCH_MORPH: "morphology",
  FILTER_SEARCH_NGRAMED: "ngramed",
  FILTER_SEARCH_SYNONYM: "synonymysed",
  FILTER_SEARCH_FREQUENCY: "frequency-term",
  FILTER_OIK: "oikCode",
  FILTER_FUND: "fundCode",
  FILTER_STATUS: "status",
  FILTER_YEAR: "year",
  FILTER_KIND: "kind",
  FILTER_TYPE: "type",
  FILTER_CATEGORY: "category",
  FILTER_INVENTORY_ADD_DATE_FROM: "createDateFrom",
  FILTER_INVENTORY_ADD_DATE_TO: "createDateTo",

  COMMENT_UPDATE_DATE: "commentUpdateDate",
  COMMENT_UPDATE_USER: "commentUpdateUser",
};
