






































































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import InfoModal from "@monorepo/inventory/src/views/ERCProtocolsView/components/InfoModal.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import ToggleTableCellsContainer from "@monorepo/inventory/src/views/ERCProtocolsView/components/ToggleTableCellsContainer.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { mapActions, mapGetters, mapMutations, Module } from "vuex";
import { breadcrumbs } from "./constants/breadcrumbs";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { module as ERCProtocolsView } from "../../store/modules/ERCProtocolsView";
import useRefreshTable from "@monorepo/utils/src/composables/useRefreshTable";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import useGetTableLibs from "./composables/useGetTableLibs";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import InventoryStatus from "@monorepo/uikit/src/components/tableViews/InventoryCountItemsStatus.vue";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/ERCProtocolsView/utils/convertApiItemToUi";
import { IERCProtocolsElement } from "@monorepo/inventory/src/views/ERCProtocolsView/types/ERCProtocolsElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/fields";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/inventory/src/views/ERCProtocolsView/utils/defaultCurrentFilters";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";
import ToggleDocumentView from "@monorepo/uikit/src/components/common/ToggleDocumentView.vue";
import FiltersTopNew from "@monorepo/uikit/src/components/common/FiltersTopNew.vue";
import TableNew from "@monorepo/inventory/src/views/ERCProtocolsView/components/TableNew.vue";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

export default defineComponent({
  name: "ERCProtocolsView",
  components: {
    TableSettings,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    ExportButton,
    TableHeader,
    Breadcrumbs,
    Footer,
    SortPanelContainer,
    InfoModal,
    ToggleTableCellsContainer,
    AutoRefreshButton,
    ExportFooterButton,
    InventoryStatus,
    ToggleDocumentView,
    FiltersTopNew,
    TableNew,
  },
  data() {
    return {
      breadcrumbs,
      sectionName: "ERCProtocolsView", // name === store module name
      viewTitle,
      tableHeaders,
      viewUniqKey,
      showEadModal: false,
      pluralFormTitles: ["Опись", "Описи", "Описей"],
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      ViewMode,
      autocompleteFiltersKeys: [fields.INVENTORY_NUMBER, fields.FILTER_OIK, fields.FILTER_KEYWORD],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters("ERCProtocolsView", [
      "cells",
      "data",
      "openedId",
      "selectedIds",
      "isSelectAll",
      "isOpenFilters",
      "infiniteId",
      "totalLength",
      "isTableLoading",
      "autorefresh",
      "isLoadingChangeAutorefresh",
      "elasticValue",
      "viewMode",
    ]),
    ...mapGetters("ERCProtocolsView", ["kindsLibrary", "categoriesLibrary", "typesLibrary", "funds"]),
    currentDocumentView(): ViewMode {
      return this.viewMode?.typeDisplay || ViewMode.TABLE;
    },
    resultFiltersElement(): any {
      return !this.elasticValue ? this.filtersElements.slice(1) : this.filtersElements;
    },
    fundNames(): { title: string; value: string; isDeleted: boolean }[] {
      return this.funds.map((fund: { name: string; code: string; isDeleted: boolean }) => ({
        title: `${fund.name} (код: ${fund.code})`,
        value: fund.code,
        isDeleted: fund.isDeleted,
      }));
    },
    statuses(): { title: string; value: string }[] {
      return convertToSelectItems(["Утверждена", "Удалена"]);
    },
    kinds(): { title: string; value: string }[] {
      return convertToSelectItems(this.kindsLibrary, "title", "code");
    },
    types(): { title: string; value: string }[] {
      return convertToSelectItems(this.typesLibrary, "title", "code");
    },
    categories(): { title: string; value: string }[] {
      return convertToSelectItems(this.categoriesLibrary, "title", "code");
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        fundNames: this.fundNames,
        statuses: this.statuses,
        kinds: this.kinds,
        types: this.types,
        categories: this.categories,
      };
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.INVENTORY_EXPORT);
    },
    isShowFooter(): boolean {
      return (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) && this.isShowExport;
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    additionalClassObj() {
      return (index: number, event: Record<string, unknown>) => {
        return {
          "monitoring-table__td_first": !index,
          "monitoring-table__td_success": !index && event.totalEadCount && event.totalEadCount === event.receivedEadCount,
        };
      };
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch("ERCProtocolsView/setIsSelectAll", value);
      },
    },
  },
  methods: {
    ...mapActions("ERCProtocolsView", ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters", "setAutorefresh"]),
    ...mapMutations("ERCProtocolsView", ["refreshData", "clearFilters"]),
    openModalIfNeed() {
      if (this.$route.query?.isOpenModal && this.data.length) {
        this.addOpenedId(this.data[0].id);
      } else if (this.$route.query?.card && this.data.length) {
        this.addOpenedId(this.$route.query?.card as string);
      }
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          this.$store.dispatch("ERCProtocolsView/addOpenedId", event.id);
          this.toggleModal(true);
          break;
        case fields.INVENTORY_OIK: {
          this.moveByAuthorities(header, "/dictionaries/oik", {
            name: event[fields.INVENTORY_OIK as keyof IERCProtocolsElement] as string,
            isOpenModal: "1",
          });
          break;
        }

        case fields.INVENTORY_FUND: {
          this.moveByAuthorities(header, "/dictionaries/fund", {
            number: event[fields.INVENTORY_FUND as keyof IERCProtocolsElement] as string,
            id: (event.fund as { id: string })?.id,
            isOpenModal: "1",
          });
          break;
        }
        default:
          break;
      }
    },
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch("ERCProtocolsView/addOpenedId", null);
      }
    },
    setCells(value: Record<string, unknown>[]) {
      this.$store.dispatch(
        "ERCProtocolsView/setCells",
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const moveByAuthorities = useMoveByAuthorities(root);

    useInitTableStoreModule(root, "ERCProtocolsView", ERCProtocolsView as Module<unknown, unknown>);
    useSetStartCells(root, "ERCProtocolsView", Sections.INVENTORY, tableHeaders, true).then(() => {
      useRefreshTable(root, "ERCProtocolsView");
    });
    useSetStartFilters(root, "ERCProtocolsView", ["id", "header", "inventoryNumber"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, "ERCProtocolsView");
    });
    useGetTableLibs(root);
    useAsyncExport(root, "ERCProtocolsView");

    return {
      filtersLoaded,
      moveByAuthorities,
    };
  },
});
