import { fields } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.INVENTORY_NUMBER]: {},
  [fields.INVENTORY_FUND]: [],
  [fields.INVENTORY_YEAR]: "",
  [fields.INVENTORY_STATUS]: [],
  [fields.INVENTORY_TITLE]: "",
  [fields.FILTER_CATEGORY]: [],
  [fields.FILTER_KIND]: [],
  [fields.FILTER_TYPE]: [],
});
