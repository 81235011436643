import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi, formDateFilter } from "@monorepo/utils/src/api/convertFiltersToApi";
import { fields } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/fields";

export const convertFiltersCustomToApi = (resultFilters: ITableFiltersObject): void => {
  convertAutocompleteItemToApi(resultFilters, fields.INVENTORY_NUMBER, `fieldFilters.${fields.INVENTORY_NUMBER}.number`);
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_OIK, `fieldFilters.${fields.FILTER_OIK}.name`);
  formDateFilter(resultFilters, "createDate");
  delete resultFilters.fieldFilters.keyword;
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_MORPH];
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_NGRAMED];
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_SYNONYM];
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_FREQUENCY];
};

export const convertSearchFiltersCustomToApi = (resultFilters: ITableFiltersObject): void => {
  convertAutocompleteItemToApi(resultFilters, fields.INVENTORY_NUMBER, `fieldFilters.${fields.INVENTORY_NUMBER}.number`);
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_OIK, `fieldFilters.${fields.FILTER_OIK}.name`);
  formDateFilter(resultFilters, "createDate");
};
