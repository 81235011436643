import { ComponentInstance, onBeforeMount } from "@vue/composition-api";

const useGetTableLibs = (root: ComponentInstance): void => {
  const store = root.$store;
  const isOpenModalOnStart = !!root.$route.query.isOpenModal;

  onBeforeMount(() => {
    store.dispatch("ERCProtocolsView/getFunds");
    store.dispatch("ERCProtocolsView/getKinds");
    store.dispatch("ERCProtocolsView/getCategories");
    store.dispatch("ERCProtocolsView/getTypes");
    store.dispatch("ERCProtocolsView/getElasticValue");
    store.dispatch("ERCProtocolsView/getViewMode", isOpenModalOnStart);
    store.dispatch("ERCProtocolsView/getStorageTypes");
    store.dispatch("ERCProtocolsView/getArchivesData");
    store.dispatch("ERCProtocolsView/getPaperStatuses");
  });
};

export default useGetTableLibs;
