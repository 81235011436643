import { v4 as uuid } from "uuid";
import { fields } from "./fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Документы",
    tooltip: "Документов получено/Документов в деле",
    defaultWidth: 180,
    value: fields.INVENTORY_DOCS,
    isLink: false,
    isSorted: false,
    isHandle: true,
    id: uuid(),
    slotName: "inventoryDocs",
  },
  {
    text: "Заголовок",
    tooltip: "Заголовок описи дел",
    defaultWidth: 200,
    value: fields.INVENTORY_TITLE,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "№ описи дел",
    tooltip: "Номер описи дел в ОИК",
    defaultWidth: 200,
    value: fields.INVENTORY_NUMBER,
    sortField: "number",
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Год",
    tooltip: "Год, за который сформирована опись дел",
    defaultWidth: 100,
    value: fields.INVENTORY_YEAR,
    sortField: "year",
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "ОИК",
    tooltip: "Краткое наименование ОИК",
    defaultWidth: 230,
    value: fields.INVENTORY_OIK,
    linkRights: [authorities.OIK_LIST],
    sortField: "oikCode",
    isLink: true,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Фонд",
    tooltip: "Номер фонда",
    defaultWidth: 110,
    value: fields.INVENTORY_FUND,
    linkRights: [authorities.FUND_LIST],
    sortField: "fundCode",
    isLink: true,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Вид",
    tooltip: "Вид описи дел может принимать значения «Больше или равно 10 лет», «Постоянно», «По личному составу»",
    defaultWidth: 130,
    value: fields.INVENTORY_CATEGORY,
    sortField: "category",
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Тип",
    tooltip: "Тип описи дел может принимать значения «Передача документов», «Изменение сроков хранения документов»",
    defaultWidth: 130,
    value: fields.INVENTORY_KIND,
    sortField: "kind",
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата утверждения описи дел",
    tooltip: "Дата утверждения описи дел",
    defaultWidth: 250,
    value: fields.INVENTORY_APPROVE_DATE,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата поступления описи дел в ЦХЭД",
    tooltip: "Дата поступления описи дел в ЦХЭД",
    defaultWidth: 250,
    value: fields.INVENTORY_ADD_DATE,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус",
    tooltip: "Статус описи дел может принимать значения: Утверждена, Удалена",
    defaultWidth: 150,
    value: fields.INVENTORY_STATUS,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
];
