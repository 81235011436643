


















import { defineComponent, ComponentInstance, computed } from "@vue/composition-api";
import draggable from "vuedraggable";
import ElectronicSignatureTableCell from "@monorepo/inventory/src/components/ElectronicSignatureTableCell.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCells from "@monorepo/uikit/src/components/tableViews/ToggleTableCells.vue";
import { mapActions, mapGetters } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { REFRESH_INVENTORY_ACT_TABLE } from "@monorepo/utils/src/eventBus/events/refreshInventoryActTable";
import moment from "moment";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";

const tableHeaders = [
  { defaultWidth: 150, value: "number", text: "№ акта", tooltip: "Номер акта приёма-передачи дел" },
  { defaultWidth: 200, value: "createdAt", text: "Дата формирования акта", tooltip: "Дата формирования акта приёма-передачи дел" },
  { defaultWidth: 200, value: "status", text: "Статус акта", tooltip: "Статус акта" },
];

export default defineComponent({
  name: "ActsTable",
  components: {
    ElectronicSignatureTableCell,
    ToggleTableCells,
    TableSettings,
    draggable,
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      tableHeaders,
      isLoading: false,
      data: [],
    };
  },
  watch: {
    id: {
      immediate: true,
      async handler(value) {
        if (value) {
          this.handleActs(value);
        }
      },
    },
  },
  beforeMount() {
    eventBus.$on(REFRESH_INVENTORY_ACT_TABLE.REFRESH, this.handleActs);
    eventBus.$on(REFRESH_INVENTORY_ACT_TABLE.CLEAR, this.clearActs);
  },
  beforeDestroy() {
    eventBus.$off(REFRESH_INVENTORY_ACT_TABLE.REFRESH, this.handleActs);
    eventBus.$on(REFRESH_INVENTORY_ACT_TABLE.CLEAR, this.clearActs);
  },
  computed: {
    ...mapGetters("ERCProtocolsView", { getCells: "documentsTableCells", documentList: "documentList" }),
    resultData(): Record<string, string>[] {
      return this.data.map((item: { number: string; createdAt: string; status: string }) => ({
        status: item.status,
        number: item.number,
        createdAt: moment(item.createdAt).format("YYYY-MM-DD HH:mm"),
      }));
    },
  },
  methods: {
    ...mapActions("ERCProtocolsView", ["getActs"]),
    clearActs() {
      this.isLoading = true;
      this.data = [];
    },
    async handleActs(value: number) {
      this.isLoading = true;
      this.data = [];
      this.data = await this.getActs(value || this.id).finally(() => {
        this.isLoading = false;
      });
    },
    handleOpenAct(number: string) {
      this.$router.push({
        path: "/inventory/acts",
        query: { number, isOpenModal: "1" },
      });
    },
  },
  setup(props: Record<string, unknown>, { root }: { root: ComponentInstance }) {
    const resultTableHeaders = computed(() => tableHeaders as unknown[]);
    const { headers } = useTableCells(root, "ERCProtocolsView", resultTableHeaders, 1120, false, false, { getCellsName: "documentsTableCells" });
    return {
      headers,
    };
  },
});
