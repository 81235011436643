


































































import { defineComponent, ref } from "@vue/composition-api";
import FiltersNew from "@monorepo/uikit/src/components/common/FiltersNew.vue";
import FiltersTopNew from "@monorepo/uikit/src/components/common/FiltersTopNew.vue";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import { viewTitle } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/viewTitle";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/tableHeaders";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/ERCProtocolsView/utils/convertApiItemToUi";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TableSearchResults from "@monorepo/uikit/src/components/common/TableSearchResults.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/fields";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/inventory/src/views/ERCProtocolsView/utils/defaultCurrentFilters";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import InventoryStatus from "@monorepo/uikit/src/components/tableViews/InventoryCountItemsStatus.vue";
import SortPanelContainerNew from "@monorepo/uikit/src/components/common/SortPanelContainerNew.vue";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

const SECTION_NAME = "ERCProtocolsView";

export default defineComponent({
  components: {
    TextFilter,
    SelectFilter,
    InfiniteScroll,
    ScrollPanel,
    TableSearchResults,
    FiltersNew,
    FindButton,
    FiltersTopNew,
    InventoryStatus,
    SortPanelContainerNew,
  },
  data() {
    return {
      viewTitle,
      search: "",
      items: [],
      sortFilter: null,
      tableHeaders,
      filtersElements,
      setCurrentFilters,
      autocompleteFiltersKeys: [fields.INVENTORY_NUMBER, fields.FILTER_OIK, fields.FILTER_KEYWORD],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user", "userSettings"]),
    ...mapGetters(SECTION_NAME, [
      "cells",
      "data",
      "openedId",
      "isOpenFilters",
      "isSelectAll",
      "infiniteId",
      "totalLength",
      "selectedIds",
      "isTableLoading",
      "autorefresh",
      "isLoadingChangeAutorefresh",
      "kindsLibrary",
      "funds",
      "viewMode",
    ]),
    currentDocumentView(): ViewMode {
      return this.viewMode?.typeDisplay || ViewMode.TABLE;
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    headers(): typeof tableHeaders {
      return this.tableHeaders.slice(1).filter((item) => item.value !== fields.INVENTORY_TITLE);
    },
    resultData(): any {
      return (this.data as any).map(convertApiItemToUi);
    },
    resultFiltersElement(): any {
      return this.filtersElements.slice(1);
    },
    fundNames(): { title: string; value: string; isDeleted: boolean }[] {
      return this.funds.map((fund: { name: string; code: string; isDeleted: boolean }) => ({
        title: `${fund.name} (код: ${fund.code})`,
        value: fund.code,
        isDeleted: fund.isDeleted,
      }));
    },
    statuses(): { title: string; value: string }[] {
      return convertToSelectItems(["Утверждена", "Удалена"]);
    },
    kinds(): { title: string; value: string }[] {
      return convertToSelectItems(this.kindsLibrary);
    },
    types(): { title: string; value: string }[] {
      return convertToSelectItems(["Передача документов", "Изменение сроков хранения документов"]);
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        fundNames: this.fundNames,
        statuses: this.statuses,
        kinds: this.kinds,
        types: this.types,
      };
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, [
      "getEventList",
      "getExportData",
      "addOpenedId",
      "setIsOpenFilters",
      "getTreeData",
      "clearFilters",
      "setAutorefresh",
    ]),
    ...mapMutations(SECTION_NAME, ["refreshData", "setSelectedIds"]),
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    openModal(header: { value: string }, event: Record<string, unknown>) {
      this.$emit("openModal", { header, event });
    },
  },
  setup() {
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    return {
      sectionName,
    };
  },
});
