import { Module } from "vuex";
import { IRootState } from "@monorepo/inventory/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi, convertSearchFiltersCustomToApi } from "@monorepo/inventory/src/views/ERCProtocolsView/utils/convertFiltersToApi";
import { IEadElement } from "@monorepo/inventory/src/views/EadView/types/eadElement";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { IDocument } from "@monorepo/inventory/src/views/EadView/types/IDocument";
import axios, { AxiosResponse } from "axios";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { cloneDeep } from "lodash";
import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";
import { IResponse } from "@monorepo/utils/src/api/types/response";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

interface ITableFiltersObjectResult extends ITableFiltersObject {
  inventoryDocumentsCells: string[];
}

interface ITableState {
  filters: ITableFiltersObjectResult;
  totalLength: number;
  data: IEadElement[];
  infiniteId: string;
  sectionCells: ISection;
  inventoryCells: string[];
  isOpenFilters: boolean;
  autorefresh: boolean;
  cells: string[];
  section: string;
  documentList: IDocument;
  searchTemplates: ISearchTemplate[];
  libraries: {
    funds: string[];
    kinds: { code: string; name: string }[];
    categories: { code: string; name: string }[];
    types: { code: string; name: string }[];
    storageTypes: any[];
    archives: any[];
    paperStatuses: Record<string, string>[];
  };
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
  settingElasticValue: boolean;
  viewMode: {
    id?: number;
    typeDisplay: ViewMode;
  };
}

const defaultFilters = (): ITableFiltersObjectResult => ({
  sort: {},
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  inventoryDocumentsCells: [],
  fieldFilters: {
    [fields.FILTER_SEARCH_MORPH]: true,
  },
  initMessagesLength: 50,
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_ERC_PROTOCOLS_CSV;
    case "XLSX":
      return QUERY_PATH.GET_ERC_PROTOCOLS_XLS;
    default:
      return QUERY_PATH.GET_ERC_PROTOCOLS_PDF;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    filters: defaultFilters(),
    infiniteId: new Date().toString(),
    totalLength: 0,
    inventoryCells: [],
    searchTemplates: [],
    isOpenFilters: false,
    autorefresh: false,
    documentList: {} as IDocument,
    sectionCells: {} as ISection,
    section: Sections.INVENTORY,
    data: [],
    cells: [],
    libraries: {
      funds: [],
      kinds: [],
      categories: [],
      types: [],
      storageTypes: [],
      archives: [],
      paperStatuses: [],
    },
    isTableLoading: false,
    isLoadingToggleFilters: false,
    isLoadingChangeAutorefresh: false,
    settingElasticValue: false,
    viewMode: {
      typeDisplay: ViewMode.TABLE,
    },
  }),
  mutations: {
    ...baseMutations,
    addStorageTypesLib(state: ITableState, payload: any[]) {
      state.libraries.storageTypes =
        payload.map((type) => {
          return { ...type, value: type.code };
        }) || [];
    },
    addArchivesLib(state: ITableState, payload: any[]) {
      state.libraries.archives =
        payload.map((archive) => {
          return { ...archive, value: archive.id, title: archive.shortName };
        }) || [];
    },
    addPaperStatusesLib(state: ITableState, payload: Record<string, string>[]) {
      state.libraries.paperStatuses = payload || [];
    },
    refreshData(state: ITableState) {
      state.data = [];
    },
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    funds(state: ITableState, payload: string[]) {
      state.libraries.funds = payload;
    },
    setCells(state: ITableState, payload: string[]) {
      state.inventoryCells = payload;
    },
    setDocumentsTableCells(state: ITableState, payload: string[]) {
      state.filters.inventoryDocumentsCells = payload;
    },
    setElasticValue(state: ITableState, payload: boolean) {
      state.settingElasticValue = payload;
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    setDocumentList(state: ITableState, payload: { data: IDocument }) {
      state.documentList = payload.data;
    },
    addData(
      state: ITableState,
      payload: {
        data: IEadElement[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.data = payload.isReset ? payload.data : state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
    addKindsLib(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.kinds = payload || [];
    },
    addCategoriesLib(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.categories = payload || [];
    },
    addTypesLib(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.types = payload || [];
    },
  },
  actions: {
    ...baseActions,
    getStorageTypes({ commit, state }) {
      checkExistLibrary(state.libraries.types, async () => {
        const { data } = await getQuery<any[]>(QUERY_PATH.GET_STORAGE_TYPES);
        commit("addStorageTypesLib", data || []);
      });
    },
    async getArchivesData({ commit }) {
      const { data } = await getQuery<any[]>(`${QUERY_PATH.GET_CATALOG_ARCHIVE_ALL}`);

      if (data !== null) {
        commit("addArchivesLib", data || []);
      }
    },
    async getPaperStatuses({ commit, state }) {
      checkExistLibrary(state.libraries.paperStatuses, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.SAVE_PAPER_STATUS);
        commit("addPaperStatusesLib", data);
      });
    },
    async savePaperStatus(info, data: Record<string, string>): Promise<boolean> {
      try {
        await axios.put(getFullPath(QUERY_PATH.SAVE_PAPER_STATUS), data).catch(function (error) {
          showNotification(error.response.data.message);
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async getSearchAutocomplete(info, params: string) {
      if (params?.length < 3) {
        return [];
      }

      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_ERC_SEARCH_AUTOCOMPLETE,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        const result = data
          .map((item: { suggestion: string }) => {
            const title = item.suggestion.replaceAll("@!!", "");
            return {
              titleValue: title,
              code: title,
            };
          })
          .filter((item: { titleValue: string }) => item.titleValue !== params);
        return [{ titleValue: params, code: params }].concat(result);
      }
      return [];
    },
    async getCaseTreeData(info, { id }) {
      const { data } = await getQuery<any[]>(`${QUERY_PATH.GET_CASE_HIERARCHY}/${id}`).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || {} };
      }

      return { data: {} };
    },
    async getEadTreeData(info, { length, id }) {
      const params = {
        offset: length,
        limit: 50,
      };

      const { data, total: totalLength } = await getQuery<any[]>(`${QUERY_PATH.GET_EAD_BY_CASE_TREE_LIST}/${id}`, params).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || [], totalLength: totalLength || 0 };
      }

      return { data: [], totalLength: 0 };
    },
    async getTreeData(info, params?: { parentNodeId?: string; archiveId?: string }) {
      const { data } = await getQuery<any[]>(`${QUERY_PATH.GET_STORAGE_CATALOG_LIST}`, params).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || {} };
      }

      return { data: {} };
    },
    async formAct(
      info,
      params: {
        oikCode: string;
        fundCode: string;
        inventoryId: string;
      }
    ) {
      try {
        const { data } = await axios.post(getFullPath(QUERY_PATH.FORM_INVNTORY_ACT), params);
        return data?.data?.id;
      } catch (e) {
        return null;
      }
    },
    async getProtocolByGuid(info, guid: string) {
      try {
        const { data } = await getQuery<IDocument>(`${QUERY_PATH.GET_INVENTORY_PROTOCOLS_BY_ID}/${guid}`);
        return data;
      } catch (e) {
        return {};
      }
    },
    setDocumentsTableCells({ commit }, payload: string[]) {
      commit("setDocumentsTableCells", payload);
    },
    async getElasticValue({ commit }) {
      const { data } = await getQuery<string[]>(QUERY_PATH.GET_ELASTIC_SETTING);
      commit("setElasticValue", data === "true");
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const filters = cloneDeep(state.filters);
      const keyword = filters.fieldFilters.keyword as unknown as { code: string };
      delete filters.fieldFilters.keyword;
      const params = convertFiltersToApi(filters, state.data.length, keyword ? convertSearchFiltersCustomToApi : convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IEadElement[]>(
        keyword ? QUERY_PATH.GET_INVENTORIES_TMP_LIST : QUERY_PATH.GET_INVENTORIES_LIST,
        {
          ...params,
          ...(keyword ? { keyword: keyword?.code } : {}),
        },
        true,
        keyword ? "Семантический поиск временно не доступен." : undefined
      ).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: !!keyword });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getInventoryElement(info, payload: string) {
      const { data } = await getQuery<IEadElement>(`${QUERY_PATH.GET_INVENTORIES_LIST}${payload}`, { withCases: false });
      return data || {};
    },
    async getActs(info, id: number) {
      const { data } = await getQuery<IAutocompleteElement[]>(`${QUERY_PATH.GET_ACTS_BY_INVENTORY}/${id}`);
      return data;
    },
    async saveComment(info, data: Record<string, string>): Promise<boolean> {
      try {
        const { entityId, comment } = data;
        await axios.put(getFullPath(QUERY_PATH.SAVE_INVENTORY_COMMENT), { entityId, comment }).catch(function (error) {
          showNotification(error.response.data.message);
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async saveHistoricalReference(info, data: Record<string, string>): Promise<boolean> {
      try {
        const { entityId, historicalReference } = data;
        await axios.put(getFullPath(QUERY_PATH.SAVE_INVENTORY_HISTORICAL_NOTE), { entityId, comment: historicalReference }).catch(function (error) {
          showNotification(error.response.data.message);
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async refreshEventList({ commit, state }) {
      const filters = cloneDeep(state.filters);
      const keyword = filters.fieldFilters.keyword as unknown as { code: string };
      delete filters.fieldFilters.keyword;
      const params = convertFiltersToApi(filters, state.data.length, keyword ? convertSearchFiltersCustomToApi : convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<IEadElement[]>(
        keyword ? QUERY_PATH.GET_INVENTORIES_TMP_LIST : QUERY_PATH.GET_INVENTORIES_LIST,
        {
          ...params,
          ...(keyword ? { keyword: keyword?.code } : {}),
          offset: 0,
        },
        true,
        keyword ? "Семантический поиск временно не доступен." : undefined
      );

      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: true });
      }
      return { data: null };
    },
    async getDocumentList({ commit }, id) {
      const { data } = await getQuery<IDocument>(`${QUERY_PATH.GET_INVENTORIES_LIST}${id}/sections`);
      if (data !== null) {
        commit("setDocumentList", { data: data || {} });
        return { data: data || {} };
      }
      return { data: null };
    },
    async getExportData({ state, dispatch, commit }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      if (payload !== "PDF") {
        dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), { root: true });
      } else {
        commit("app/setIsExportFileLoading", true, {
          root: true,
        });
        const { data } = await getQuery<void>(QUERY_PATH.GET_ERC_PROTOCOLS_PDF_ASYNC, params);
        if (!data) {
          commit("app/setIsExportFileLoading", false, {
            root: true,
          });
        }
      }
    },
    getFunds({ commit, state }) {
      checkExistLibrary(state.libraries.funds, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_FUND_SHORT_ITEM_LIB);
        commit("funds", data || []);
      });
    },
    getKinds({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.kinds, async () => {
          const { data }: AxiosResponse<IResponse<{ code: string; name: string }[]>> = await axios.get(getFullPath(QUERY_PATH.GET_INVENTORIES_KINDS));
          commit("addKindsLib", data || []);
        });
      } catch (e) {
        showNotification("Ошибка получения списка типов описи.");
      }
    },
    getCategories({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.categories, async () => {
          const { data }: AxiosResponse<IResponse<{ code: string; name: string }[]>> = await axios.get(
            getFullPath(QUERY_PATH.GET_INVENTORIES_CATEGORIES)
          );
          commit("addCategoriesLib", data || []);
        });
      } catch (e) {
        showNotification("Ошибка получения списка видов описи.");
      }
    },
    getTypes({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.types, async () => {
          const { data }: AxiosResponse<IResponse<{ code: string; name: string }[]>> = await axios.get(getFullPath(QUERY_PATH.GET_INVENTORIES_TYPES));
          commit("addTypesLib", data || []);
        });
      } catch (e) {
        showNotification("Ошибка получения списка типов хранения.");
      }
    },
    async getInvAutocompleteByNumber(info, value: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_INVENTORIES_AUTOCOMPLETE_ITEMS,
        {
          keyword: value,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          id: item.number,
          number: item.number,
          titleValue: item.number,
        }));
      }
      return [];
    },
  },
  getters: {
    ...baseGetters,
    storageTypes(state: ITableState) {
      return state.libraries.storageTypes || [];
    },
    archives(state: ITableState) {
      return state.libraries.archives || [];
    },
    paperStatuses(state: ITableState) {
      return state.libraries.paperStatuses;
    },
    cells(state: ITableState) {
      return state.inventoryCells ?? [];
    },
    documentsTableCells(state: ITableState) {
      return state.filters?.inventoryDocumentsCells ?? [];
    },
    documentList(state: ITableState) {
      return state.documentList ?? {};
    },
    funds(state: ITableState) {
      return state.libraries.funds || [];
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    kindsLibrary(state: ITableState) {
      return state.libraries.kinds || [];
    },
    categoriesLibrary(state: ITableState) {
      return state.libraries.categories || [];
    },
    typesLibrary(state: ITableState) {
      return state.libraries.types || [];
    },
    elasticValue(state: ITableState) {
      return state.settingElasticValue;
    },
  },
};
